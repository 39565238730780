<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-container
      fluid
      class="px-6"
    >
      <div>
        <h1 class="display-1 font-weight-bold">Détails du produit</h1>
        <!-- <p class="subtitle-1 grey--text"><strong>ID:</strong> A1234567890</p> -->
      </div>
      <v-tabs
        class="mt-6"
        v-model="tab"
        background-color="primary"
        center-active
        dark
      >
        <v-tab href="#general-info">{{ $t("products.general_info") }}</v-tab>
        <v-tab href="#pricing">{{ $t("products.pricing") }}</v-tab>
        <v-tab href="#marketing-info">{{
          $t("products.marketing_info")
        }}</v-tab>
        <v-tab href="#hubs">{{ $t("hubs") }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item
          :key="1"
          :value="'general-info'"
        >
          <!-- Genenral Information Section -->
          <div
            class="d-flex flex-column flex-md-row"
            style="gap: 50px"
          >
            <!-- General Information - Left Section -->
            <div
              class="flex-grow-1 mt-8"
              style="min-width: 45%"
            >
              <h4 class="mt-2">Product information</h4>
              <packs-and-bundles-view-loader v-if="!loaded" />
              <div
                v-if="loaded"
                class="mt-8"
              >
                <p class="d-flex justify-space-between my-4">
                  <span class="font-weight-medium">{{ $t("name") }}</span>
                  <span class="text-capitalize">
                    {{ product.name }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-4">
                  <span class="font-weight-medium">{{
                    $t("product_type")
                  }}</span>
                  <span class="text-capitalize">
                    {{ product.type }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-4">
                  <span class="font-weight-medium">{{ $t("barcodes") }}</span>
                  <span>{{ product.barcodes[0]?.code }}</span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-4">
                  <span class="font-weight-medium">{{ $t("shelf") }}</span>
                  <span>
                    {{ currentShelf?.name }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-4">
                  <span class="font-weight-medium">{{ $t("category") }}</span>
                  <span>{{ currentRoot?.name }}</span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-4">
                  <span class="font-weight-medium">{{
                    $t("sub_category")
                  }}</span>
                  <span>{{ currentCategory?.name }}</span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-4">
                  <span class="font-weight-medium">{{ $t("brand") }}</span>
                  <span>{{ product.brand?.name }}</span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <template v-if="product.type === 'pack'">
                  <p class="d-flex justify-space-between my-4">
                    <span class="font-weight-medium">{{
                      $t("setup.grouped_products.max_order_quantity")
                    }}</span>
                    <span>{{
                      product.max_order_qte !== null &&
                      product.max_order_qte !== undefined
                        ? product.max_order_qte === 0
                          ? $t("setup.grouped_products.no_limit")
                          : product.max_order_qte
                        : "-"
                    }}</span>
                  </p>
                  <hr style="border-top: 1px solid #d3d3d3" />
                </template>
              </div>
            </div>
            <!-- /General Information - Left Section -->
            <!-- General Information - Right Section -->
            <div
              class="flex-grow-1 mt-8"
              style="min-width: 45%"
            >
              <h4 class="mt-2">{{ $t("product_info") }}</h4>
              <packs-and-bundles-view-loader v-if="!loaded" />
              <div
                v-if="loaded"
                class="mt-8"
              >
                <p class="d-flex justify-space-between my-4">
                  <span class="font-weight-medium">{{
                    $t("item_net_weight")
                  }}</span>
                  <span>
                    {{
                      product.product_info?.item_net_weight.concat(
                        product.product_info?.item_net_weight_unit
                      ) ?? "-"
                    }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-4">
                  <span class="font-weight-medium">{{
                    $t("item_gross_weight")
                  }}</span>
                  <span>
                    {{
                      product.product_info?.item_gross_weight.concat(
                        product.product_info?.item_gross_weight_unit
                      ) ?? "-"
                    }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-4">
                  <span class="font-weight-medium">{{
                    $t("item_net_volume")
                  }}</span>
                  <span>
                    {{
                      product.product_info?.item_net_volume.concat(
                        product.product_info?.item_net_volume_unit
                      ) ?? "-"
                    }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-4">
                  <span class="font-weight-medium">{{
                    $t("item_gross_volume")
                  }}</span>
                  <span>
                    {{
                      product.product_info?.item_gross_volume.concat(
                        product.product_info?.item_gross_volume_unit
                      ) ?? "-"
                    }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
              </div>
              <br />
              <h4 class="mt-8">
                {{ $t("setup.grouped_products.bundle_items") }}
              </h4>
              <v-card
                v-if="product?.bundle_items?.length > 0"
                elevation="6"
                class="ma-2 my-6"
              >
                <v-row>
                  <v-col cols="3"> </v-col>
                  <v-col cols="7">
                    <p class="font-weight-bold">Product</p>
                  </v-col>
                  <v-col cols="2">
                    <p class="font-weight-bold">Qty</p>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <template v-for="(item, i) in product.bundle_items">
                  <template>
                    <v-row
                      class="justify-left align-center my-2"
                      mb-3
                      :key="`bundleItem-${i}`"
                    >
                      <v-col cols="3">
                        <div class="d-flex justify-center">
                          <v-img
                            v-if="item?.images.length > 0"
                            :src="item?.images[0]?.url"
                            max-height="75"
                            max-width="75"
                          />
                          <v-img
                            v-else
                            src="@/assets/product.png"
                            max-height="50"
                            max-width="50"
                          />
                        </div>
                      </v-col>
                      <v-col cols="7">
                        <h4>{{ item?.name }}</h4>
                        <p>
                          ID:&nbsp;{{ item?.code }} -
                          {{ $t("barcode") + ": " + item?.sku }}
                        </p>
                      </v-col>
                      <v-col cols="2">
                        <p class="font-weight-bold">
                          {{ item?.qte_in_bundle }}
                        </p>
                      </v-col>
                    </v-row>
                    <v-divider :key="`bundleItem-${i}-divider`"></v-divider>
                  </template>
                </template>
              </v-card>
            </div>
            <!-- General Information - Right Section -->
          </div>
          <!-- /General Information Section -->
        </v-tab-item>
        <v-tab-item
          :key="2"
          :value="'pricing'"
        >
          <div
            class="mt-10 d-flex"
            style="gap: 50px"
          >
            <div
              class="flex-grow-1"
              style="max-width: 700px"
            >
              <h4 class="mt-2">{{ $t("pricing_details") }}</h4>
              <packs-and-bundles-view-loader v-if="!loaded" />
              <div
                v-if="loaded"
                class="mt-8 font-weight-medium"
              >
                <p class="d-flex justify-space-between my-2">
                  <span>{{
                    $t("setup.grouped_products.calculated_selling_price")
                  }}</span>
                  <span>
                    {{
                      product.purchase_price_ht &&
                      product.purchase_tva !== undefined &&
                      new Intl.NumberFormat("fr-FR", {
                        style: "currency",
                        currency: user.currency,
                      }).format(product.purchase_price_ht)
                    }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-2"></p>
                <p class="d-flex justify-space-between my-4">
                  <span>{{ $t("selling_price_ht") }}</span>
                  <span>
                    {{
                      product.price &&
                      product.tva != undefined &&
                      new Intl.NumberFormat("fr-FR", {
                        style: "currency",
                        currency: user.currency,
                      }).format(product.price_ht)
                    }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-2">
                  <span>{{ $t("tva") }}</span>
                  <span>{{ product.tva && product.tva + "%" }}</span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-2">
                  <span>{{ $t("TVA value") }}</span>
                  <span>{{
                    product.price &&
                    product.tva != undefined &&
                    new Intl.NumberFormat("fr-FR", {
                      style: "currency",
                      currency: user.currency,
                    }).format(product.price - product.price_ht)
                  }}</span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-2">
                  <span>{{ $t("price_ttc") }}</span>
                  <span>
                    {{
                      product.price &&
                      product.purchase_tva != undefined &&
                      new Intl.NumberFormat("fr-FR", {
                        style: "currency",
                        currency: user.currency,
                      }).format(product.price)
                    }}
                  </span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-2">
                  <span>{{
                    $t("setup.grouped_products.price_difference")
                  }}</span>
                  <span>{{
                    (
                      ((product.price - product.purchase_price_ht) * 100) /
                      product.purchase_price_ht
                    ).toFixed(3) + " %"
                  }}</span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
                <p class="d-flex justify-space-between my-2">
                  <span>{{
                    $t("setup.grouped_products.price_difference_value")
                  }}</span>
                  <span>{{
                    new Intl.NumberFormat("fr-FR", {
                      style: "currency",
                      currency: user.currency,
                    }).format(product.price - product.purchase_price_ht)
                  }}</span>
                </p>
                <hr style="border-top: 1px solid #d3d3d3" />
              </div>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item
          disabled
          :key="3"
          :value="'marketing-info'"
        >
          <div
            class="mt-10"
            style="max-width: 700px"
          >
            <h4>Marketing détails</h4>
            <packs-and-bundles-view-loader v-if="!loaded" />
            <div
              v-if="loaded"
              class="mt-8 font-weight-medium"
            >
              <p class="d-flex justify-space-between my-4">
                <span>{{ $t("marketing_designation") }}</span>
                <span class="text-capitalize">
                  {{ product.name_marketing }}
                </span>
              </p>
              <hr style="border-top: 1px solid #d3d3d3" />
              <p class="d-flex justify-space-between align-center my-2">
                <span>Express Status</span>
                <v-switch
                  disabled
                  v-model="product.express_status"
                ></v-switch>
              </p>
              <hr style="border-top: 1px solid #d3d3d3" />
              <div class="mt-2">
                <span>Short Description</span>
                <vue-editor
                  disabled
                  class="my-4"
                  v-model="product.short_description"
                />
              </div>
              <hr style="border-top: 1px solid #d3d3d3" />
              <div class="my-2">
                <span>Description</span>
                <vue-editor
                  disabled
                  class="my-4"
                  v-model="product.description"
                />
              </div>
              <hr style="border-top: 1px solid #d3d3d3" />
              <div class="my-2">
                <span>Special Categories</span>
                <p>
                  {{ product.special_category_ids }}
                </p>
              </div>
              <hr style="border-top: 1px solid #d3d3d3" />
              <div class="d-flex justify-space-between align-center my-2">
                <span>Product Image</span>
                <img
                  :src="product?.image?.url"
                  style="height: 100px"
                />
              </div>
              <hr style="border-top: 1px solid #d3d3d3" />
            </div>
          </div>
        </v-tab-item>
        <v-tab-item
          :key="4"
          :value="'hubs'"
        >
          <view-hubs-tab
            :product="product"
            :loaded="loaded"
          ></view-hubs-tab>
        </v-tab-item>
      </v-tabs-items>
      <div class="mt-10">
        <v-btn
          text
          @click="returnToList()"
        >
          {{ $t("return") }}
        </v-btn>
      </div>
    </v-container>
  </v-form>
</template>
<script>
import { mapGetters } from "vuex";
// import "./form-style.css";
import { GroupedProduct } from "@/classes/packAndBundle.class";
import { validationRules } from "@/mixins/validationRules";
import _ from "lodash";
import productService from "@/store/services/product-service";
import { VueEditor } from "vue2-editor";
import PacksAndBundlesViewLoader from "./PacksAndBundlesViewLoader";
import ViewHubsTab from "./tabs/ViewHubsTab";

export default {
  mixins: [validationRules],
  components: {
    VueEditor,
    ViewHubsTab,
    PacksAndBundlesViewLoader,
  },
  data() {
    return {
      tab: null,
      valid: false,
      loaded: false,
      product: new GroupedProduct(),
      productEntity: null,
      currentRoot: null,
      currentShelf: null,
      currentCategory: null,
      content: "<p>Product Description</p>",
      volumeUnits: ["ml", "cl", "L", "mm3", "cm3", "m3"],
      weightUnits: ["g", "kg"],
      productTypes: [
        {
          name: "Simple",
          value: "simple",
        },
        {
          name: "Package",
          value: "package",
        },
        {
          name: "Bundle",
          value: "bundle",
        },
      ],
      checkingBarcode: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoadingShelves: "categories/isLoadingCategories",
      shelves: "categories/listShelves",
      categories: "categories/listRoots",
      leafs: "categories/listLeafs",
      brands: "brands/activeBrands",
      leafSpecialCategories: "specialCategories/listLeafs",
      deliveryTypes: "deliveryTypes/list",
      user: "users/editedUser",
    }),
  },
  mounted() {
    const editedUserJSON = localStorage.getItem("user");
    if (editedUserJSON) {
      const editedUser = JSON.parse(editedUserJSON);
      this.$store.commit("users/EDITED_USER", editedUser);
    }
  },
  methods: {
    loadProductTypes() {},
    loadShelves() {
      return this.$store.dispatch("categories/listShelves");
    },
    loadCategories() {
      return this.$store.dispatch("categories/listRoots");
    },
    loadSubCategories() {
      return this.$store.dispatch("categories/listLeafs");
    },
    loadBrands() {
      return this.$store.dispatch("brands/list");
    },
    loadDeliveryTypes() {
      return this.$store.dispatch("deliveryTypes/list");
    },
    init() {
      this.loadProductTypes();
      this.loadShelves();
      this.loadCategories();
      this.loadSubCategories();
      this.loadBrands();
      this.loadDeliveryTypes();
      this.$store.dispatch("specialCategories/listLeafs");
    },
    returnToList() {
      this.$router.push({ name: "setup.grouped_products.index" });
    },
    validate() {
      this.$refs.form.validate();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    calculateMarge() {
      const purchase_price_ttc =
        this.product.purchase_price_ht * (1 + this.product.purchase_tva / 100);
      return Number(
        (100 * (this.product.price - purchase_price_ttc)) / purchase_price_ttc
      ).toFixed(2);
    },
    generateBarCode(index) {
      this.product.barcodes[index].code = parseInt(
        "200" + Math.floor(Math.random() * 900000000) + 1
      );
    },
    addBarCode() {
      if (this.product.barcodes.length >= 3) {
        this.$swal("", "Barcode limit reached", "info");
      } else {
        this.product.barcodes.push({
          is_main: false,
          code: null,
        });
      }
    },
    removeBarCode(barcodeIndex) {
      this.product.barcodes = this.product.barcodes.filter(
        (value, index) => index !== barcodeIndex
      );
    },
    shelfChanged(value) {
      this.currentRoot = null;
      this.currentCategories = this.categories.filter(
        (category) => category.parent_id === value
      );
    },
    rootChanged(value) {
      this.product.category_id = null;
      this.currentSubCategories = this.leafs.filter(
        (leaf) => leaf.parent_id === value
      );
    },
    NumbersOnly(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    debounceCheckBarcode: _.debounce(function (e, index) {
      this.checkBarCode(e, index);
    }, 1000),
    checkBarCode() {
      this.checkingBarcode = true;
      setTimeout(() => {
        this.checkingBarcode = false;
      }, 2000);
    },
  },
  watch: {
    product: function (newProduct) {
      const subCategory = this.leafs.find(
        (category) => category.id == newProduct?.category.id
      );
      this.currentCategory = subCategory;
      const root = this.categories.find(
        (category) => category.id == subCategory?.parent_id
      );
      this.currentRoot = root;
      const shelf = this.shelves.find((shelf) => shelf.id == root?.parent_id);
      this.currentShelf = shelf;
    },
  },
  async created() {
    this.init();
    this.productEntity = await productService.show({
      id: this.$route.params.id,
    });
    this.product = this.productEntity;
    this.loaded = true;
  },
};
</script>